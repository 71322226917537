<template>
    <template-form acao="INSERIR" @salvar="inserir($event)" :cancelar="cancelar" :erros="erros"></template-form>
</template>

<script>
import TemplateForm from './TemplateForm';
import TemplatesService from './services';

export default {
    components: {
        TemplateForm,
    },

    data() {
        return {
            erros: [],
        };
    },

    computed: {},

    methods: {
        cancelar() {
            this.$router.push({ name: `Templates` });
        },

        inserir(templateInserido) {
            this.$store.dispatch('addRequest');
            let templateInseridoDto = {
                codigo: templateInserido.codigo,
                descricao: templateInserido.descricao,
                dados: templateInserido.dados,
            };
            if (templateInserido.arquivo) {
                templateInseridoDto.arquivo = templateInserido.arquivo;
            }
            TemplatesService.inserir(templateInseridoDto).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Templates',
                        detail: 'Template inserido com sucesso',
                        life: 3000,
                    });
                    this.toDetalhar(response.data);
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        toDetalhar(templateId) {
            this.$store.dispatch('setAtualizar', true);
            this.$router.push({
                name: `Templates_Detalhar`,
                params: {
                    id: templateId,
                },
            });
        },
    },
};
</script>
